import { JSEncrypt } from "jsencrypt"
const publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDwg13VOsZIA3tzF1pIevDIbdUj"+
"7ob+lbki0XpHQ+OJR2kjAx0IVn0CfvVUoOzQ2uIgjNQSxcojaxYYIydtmeBmLcKK"+
"fnhEvGfIzUzlBUAyTSzEHhynlSJVmpeodY6pznnDOkCPOtREes7w+VN019l5FFCA"+
"DCqZLf6ZJxbi0N7TDwIDAQAB"

var dateYYYYMMDD = function(dateString) {
    var list = dateString.split("-")
    if (list[1].length == 1){
        list[1] = "0" + list[1]
    }
    if (list[2].length == 1){
        list[2] = "0" + list[2]
    }
    return list.join("-")
}
// 判断是否是数组
function isArray(obj) {
  return Object.prototype.toString.call(obj) === '[object Array]';
}

//判断是否是JSON(返回Boolean)
function isJson(param) {
    try {
        return (Object.prototype.toString.call(param) === "[object Object]")
    } catch(e) {
        console.log(param)
    }
    //console.log("不是json")
    return false;
}
// 保留2位小数
export function toDecimal(x) {   
    var f = parseFloat(x);   
    if (isNaN(f)) {   
        return;   
    }   
    f = Math.round(x*100)/100;   
    return f;   
}   
//返回一个obj的复制(返回obj)
export function copyObj (oldObj){
    var obj;
    if ((isJson(oldObj) && (obj = new Object())) || (isArray(oldObj) && (obj = new Array()))){
        if(oldObj.Copy){
            obj = oldObj.Copy()
        } else {
            for(var i in oldObj){
                obj[i] = (isJson(oldObj[i]) || isArray(oldObj[i]))
                    ? copyObj(oldObj[i])
                    : oldObj[i];
            }
        }
    }
    return obj;
}
// 获取用户的所在的地区的语言
export function getLanguage (){
    var currentLang;  
    currentLang = navigator.language;  
    if(!currentLang)
        currentLang = navigator.browserLanguage;
    if(currentLang == 'zh-CN' || currentLang === 'zh-cn'){  //中文 环境 
        currentLang = 'zh';
        // currentLang = 'en';
    }else{
        currentLang = 'en';     // 英文 环境
    }
    return currentLang;
}
//获取操作系统版本
export function detectOS() {
    var sUserAgent = navigator.userAgent;
    var isWin = (navigator.platform == "Win32") || (navigator.platform == "Windows");
    var isMac = (navigator.platform == "Mac68K") || (navigator.platform == "MacPPC") || (navigator.platform == "Macintosh") || (navigator.platform == "MacIntel");
    if (isMac) return "Mac";
    var isUnix = (navigator.platform == "X11") && !isWin && !isMac;
    if (isUnix) return "Unix";
    var isLinux = (String(navigator.platform).indexOf("Linux") > -1);
    if (isLinux) return "Linux";
    if (isWin) {
        var isWin2K = sUserAgent.indexOf("Windows NT 5.0") > -1 || sUserAgent.indexOf("Windows 2000") > -1;
        if (isWin2K) return "Win2000";
        var isWinXP = sUserAgent.indexOf("Windows NT 5.1") > -1 || sUserAgent.indexOf("Windows XP") > -1;
        if (isWinXP) return "WinXP";
        var isWin2003 = sUserAgent.indexOf("Windows NT 5.2") > -1 || sUserAgent.indexOf("Windows 2003") > -1;
        if (isWin2003) return "Win2003";
        var isWinVista= sUserAgent.indexOf("Windows NT 6.0") > -1 || sUserAgent.indexOf("Windows Vista") > -1;
        if (isWinVista) return "WinVista";
        var isWin7 = sUserAgent.indexOf("Windows NT 6.1") > -1 || sUserAgent.indexOf("Windows 7") > -1;
        if (isWin7) return "Win7";
        var isWin10 = sUserAgent.indexOf("Windows NT 10") > -1 || sUserAgent.indexOf("Windows 10") > -1;
        if (isWin10) return "Win10";
    }
    return "other";
}

export function nPw (user_id , user_passwd){
    var encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKey);
    var doBefore = user_id + "\r\n" + user_passwd + "\r\n" + parseInt(new Date().getTime()/1000);
    var pw = encrypt.encrypt(doBefore)
    return pw;
}


// n天前
export function nDaysAgo (n, startDate){
    var date = new Date(new Date().getTime() - 1000* 60* 60* 24* n);
    if(startDate) {
        date = new Date(new Date(startDate).getTime() - 1000* 60* 60* 24* n);
    }
    return dateYYYYMMDD(date.getFullYear() + "-" + (date.getMonth()+1 % 12) + "-" + date.getDate());
}
// n个月前 (的1号)
export function nMonthsAgo (n, startDate){
    if(startDate){
        return dateYYYYMMDD(new Date(new Date(new Date().setDate(1)).setMonth((new Date(startDate)).getMonth() - n)).toLocaleDateString().replace(/\//g,"-"))
    }
    return dateYYYYMMDD(new Date(new Date(new Date().setDate(1)).setMonth((new Date()).getMonth() - n)).toLocaleDateString().replace(/\//g,"-"))
}
export function nDateRound (start, end, type){ // type = 1 :按1天跨度, type =2 按1个月跨度
    var result =[]
    if(type == 1){
        var n = (new Date(end) - new Date(start))/(1000*60*60*24)
        for(let i =0 ; i <= n; i++){
            result.push(nDaysAgo(0-i, start))
        }
    } else if (type ==2){
        for(let i =0 ; new Date(nMonthsAgo(0-i,start)) < new Date(nMonthsAgo(-1,end)) ; i++) {
            var list = nMonthsAgo(0-i,start).split("-")
            result.push(list[0]+"-"+list[1])
        }
    }
    return result
}
export function timestampToString (stamp) {
    var date = new Date(stamp);
    
    return date.getFullYear() + "-" + 
        (date.getMonth()+1<=9 ? "0": "") + (date.getMonth()+1) + "-" + 
        (date.getDate() <=9 ?"0" :"") + date.getDate() + " " + 
        (date.getHours() <= 9 ? "0" :  "") + date.getHours() + ":" + 
        (date.getMinutes() <= 9 ? "0" :"") + date.getMinutes() + ":" + 
        (date.getSeconds() <= 9 ? "0" :"") + date.getSeconds()
    // return dateYYYYMMDD(date.getFullYear() + "-" + (date.getMonth()+1 % 12) + "-" + date.getDate());
}
export function timestampToString1000 (stamp) {
    var date = new Date(stamp * 1000);
    
    return date.getFullYear() + "-" + 
        (date.getMonth()+1<=9 ? "0": "") + (date.getMonth()+1) + "-" + 
        (date.getDate() <=9 ?"0" :"") + date.getDate() + " " + 
        (date.getHours() <= 9 ? "0" :  "") + date.getHours() + ":" + 
        (date.getMinutes() <= 9 ? "0" :"") + date.getMinutes() + ":" + 
        (date.getSeconds() <= 9 ? "0" :"") + date.getSeconds()
    // return dateYYYYMMDD(date.getFullYear() + "-" + (date.getMonth()+1 % 12) + "-" + date.getDate());
}

// 校验用 class  *** 返回true才表示报错 ***
export class FormCheckClass{
    constructor(checkJson){ // {'参数KEY': '参数类型'}  参数类型可以是 'string' [Array] function()
        if(!checkJson){
            return
        }
        this.__Check__ = {
            ...checkJson,
        }
        // this.__Key__ = Object.keys(this.__Check__)
        this.__Key__.forEach(key => {
            this[key] = false; // true : 建议失败
        })
    }
    get __Key__(){
        return Object.keys(this.__Check__)
    }
    get Error(){ // 返回一个Boolean [true: 存在不符合条件参数, false: 全部合格]
        return this.__Key__.map(key => {
            return this[key]
        }).some(value => value)
    }
    get ErrorList () { // 返回一个数组所有没通过校验有问题的key
        return this.__Key__.filter(key => {
            return this[key]
        })
    }
    Reset(){
        this.__Key__.forEach(key => {
            this[key] = false
        })
    }
    Check(verif_param){ // 
        this.__Key__.forEach(key => {
            let value = verif_param[key];
            let check = this.__Check__[key]
            switch(typeof check){
                case "string":
                    if(!this["Check_" + check]){
                        console.log("该功能暂时没做:", check)
                    } else {
                        this[key] = this["Check_" + check](value)
                    }
                    break
                case "function":
                    this[key] = check(value)
                    break
                case "Object":
                    console.log("该功能暂时没做:", check)
                    break
            }
        })
        if(this.Error){
            console.error("错误 参数:", this.ErrorList)
        }
        return this.Error
    }
    Check_int (val){
        return isNaN(val) || val === ""
    }
    Check_number (val){
        return isNaN(val) || val === ""
    }
    Check_required (val){
        return !val
    }
}
// 表单 class  暂时只是用来 重置参数/检查参数是否全部合法
export class FormClass{
    constructor(formInit, check){
        this.__Init__ = {...formInit}
        this.Form = {...formInit}
        this.Error = new FormCheckClass(check)
        // for(let key in this.__Init__){
        //     this[key] = this.__Init__[key]
        // }
    }
    get ErrorList () { // 返回一个数组所有没通过校验有问题的key
        return this.Error.ErrorList;
    }
    Reset(){
        this.Form = {...this.__Init__}
        this.Error.Reset();
    }
    Update(val){
        this.Form = {...val};
        this.Error.Reset();
    }
    Check(){
        return this.Error.Check(this.Form);
    }
}