// =========================================================
// * Vue Material Dashboard PRO - v1.5.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";

// router setup
import routes from "./routes/routes";

import VueI18n from "vue-i18n";

import VueMaterial from "vue-material";

import translate from "./common/lang/translate";
import {api} from "@/common/interface.js";
import "/public/css/extracss.scss"
import "/public/css/menu/iconfont.css"
// plugin setup
Vue.use(VueRouter);
Vue.use(VueMaterial);
Vue.use(VueI18n) // 通过插件的形式挂载

const i18n = new VueI18n({
    locale: 'zh-CN',    // 语言标识
    //this.$i18n.locale // 通过切换locale的值来实现语言切换
    messages: {
      'zh-CN': translate,   // 中文语言包
    }
})

// configure router
const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  scrollBehavior: (to) => {
    if(to.fullPath !== "/login"){
      // router.push("/login")
    }
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
  linkExactActiveClass: "nav-item active",
});

// I18N 国家化
Vue.prototype.locale = "zh-CN"
Vue.prototype.$I18n = function(key){
  var lang = "zh"
  if (router.history.current.query.lang === "en"){
    lang = "en"
  }
  let res = i18n.t(`${lang}.${key}`)
  if (arguments.length === 1){
    return res;
  } else if(arguments.length > 1){
    for(let i = 1; i < arguments.length; i++){
      res = res.replace(`%${i}%`, arguments[i]);
    }
    return res;
  } else {
    return "";
  }
}
Vue.prototype.$En = function(key){
  var lang = "en"
  let res = i18n.t(`${lang}.${key}`)
  if (arguments.length === 1){
    return res;
  } else if(arguments.length > 1){
    for(let i = 1; i < arguments.length; i++){
      res = res.replace(`%${i}%`, arguments[i]);
    }
    return res;
  } else {
    return "";
  }
}
// API
Vue.prototype.$api = {
  __isSendingRequest__ : 0, // 大于0的时候开启弹窗
}
window._test = function(){
  Vue.prototype.$api.__isSendingRequest__ = Vue.prototype.$api.__isSendingRequest__ + 1;
}
window.__test= function() {
  Vue.prototype.$api.__isSendingRequest__ = Vue.prototype.$api.__isSendingRequest__ - 1;
}
for (let key in api) {
  if(key.indexOf("__") == -1){
    Vue.prototype.$api[key] = function(data){
      Vue.prototype.$api.__isSendingRequest__ = Vue.prototype.$api.__isSendingRequest__ + 1
      return api[key](data)
        .then(res => {
          if(res.code == 401){
            throw res
          }
          Vue.prototype.$api.__isSendingRequest__ = Vue.prototype.$api.__isSendingRequest__ - 1
          return res
        })
        .catch (err => {
          Vue.prototype.$api.__isSendingRequest__ = Vue.prototype.$api.__isSendingRequest__ - 1
          if(err.message === "http_error_514"){
            Vue.prototype.$errorMsgIsShowing = true;
            setTimeout(() => {
              Vue.prototype.$errorMsgIsShowing = false;
            },2000)
            Vue.prototype.userInfo = {};
            router.push("/login").catch(() => {});
            return {
              code : 401,
            }
          }
          throw err
        })
    }
  } else {
    Vue.prototype.$api[key] = api[key]
  }
}
// 普通使用的弹窗
Vue.prototype.$errorMsgIsShowing = false;
Vue.prototype.$successMsg = function(msg) {
    if(this.$errorMsgIsShowing){
      return
    }
    this.$notify({
      message: msg,
      icon: 'check',
      horizontalAlign: "center",
      verticalAlign: "top",
      type: "success",
      timeout: 2000,
    })
}
Vue.prototype.$genThen = function(res){ // 检查API请求回调的Code是否是200
  if(res.code != 200){
    throw new Error ("Request failed, CDOE: " + res.code)
  }
  return res;
}
Vue.prototype.$errorMsg = function(msg) {
    if(this.$errorMsgIsShowing){
      return
    }
    this.$errorMsgIsShowing = true
    setTimeout(()=>{
      this.$errorMsgIsShowing = false
    } ,1900)
    this.$notify({
      message: msg,
        icon: 'error',
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "danger",
        timeout: 2000,
      })
}
Vue.prototype.$genCatch = function(msg){ // 检查API请求回调的Code是否是200
  return err => {
    console.error(err)
    if(msg){
      this.$errorMsg(msg);
    }
    throw err
  }
}

// 登录用户信息
Vue.prototype.$setUserInfo =  function(newInfo){
  Vue.prototype.UserInfo = newInfo
}
// Vue.prototype._mobile_ = false
// Vue.prototype.$checkSize =  function(){
//   this._mobile_ = window.innerWidth < 599
//   console.log(this._mobile_)
// }
Vue.prototype.UserInfo = {}


/* eslint-disable no-new */
new Vue({
  el: "#app",
  // i18n,
  render: (h) => h(App),
  router,
  data(){
    return {
      state : {
        mobile: false,
      },
    }
  },
  methods:{
    $checkSize :  function(){
      this.state.mobile = window.innerWidth < 599
    }
  },
  watch : {
    $route(to,from){
      if(to.path !== from.path && document.getElementsByClassName("faq-main-box")[0]){
        document.getElementsByClassName("faq-main-box")[0].scrollIntoView({})
      }
    }
  },
  created(){
    this.$checkSize();
    window.addEventListener('resize', this.$checkSize)
  },
  destroy(){
    window.addEventListener('resize', this.$checkSize, false)
  },
});
