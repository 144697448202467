let faqMenu = {
  path: "/zh",
  component: () => import("@/pages/Dashboard/Faq/Layout.vue"),
  name: "CN_FAQ",
  redirect: "/zh/info",
  children: [

    {
      path     : "info",
      name     : "ProductIntroduction",
      menu_name: "产品介绍",
      component: () => import("@/pages/Dashboard/Faq/Info/ProductIntroduction.vue"),
    },{
      path     : "guide",
      name     : "Guide",
      menu_name: "客户端开发指南",
      redirect : "/zh/guide/start",
      component: {render: e => e('router-view')},
      children : [{
          path: "start",
          name: "Start",
          menu_name: "初始化",
          component: () => import("@/pages/Dashboard/Faq/Guide/Start.vue"),
        },{
          path: "apweb",
          name: "Apweb",
          menu_name: "AP配网流程",
          component: () => import("@/pages/Dashboard/Faq/Guide/Apweb.vue"),
        },{
          path: "qrcode",
          name: "Qrcode",
          menu_name: "二维码配网流程",
          component: () => import("@/pages/Dashboard/Faq/Guide/Qrcode.vue"),
        },{
          path: "user",
          route_name: "Guide User",
          menu_name: "用户服务",
          component: () => import("@/pages/Dashboard/Faq/Guide/User.vue"),
        },{
          path: "device",
          name: "Guide_DeviceInfo",
          menu_name: "设备管理",
          component: () => import("@/pages/Dashboard/Faq/Guide/Device.vue"),
        },{
          path: "msgservice",
          name: "Guide_MsgService",
          menu_name: "消息服务",
          component: () => import("@/pages/Dashboard/Faq/Guide/MsgService.vue"),
        },{
          path: "cloud",
          name: "Guide_CloudService",
          menu_name: "云存储服务",
          component: () => import("@/pages/Dashboard/Faq/Guide/CloudService.vue"),
        },{
          path: "camera",
          name: "Camera",
          menu_name: "摄像头服务",
          component: () => import("@/pages/Dashboard/Faq/Guide/Camera.vue"),
        },{
          path: "app",
          name: "App",
          menu_name: "APP服务",
          component: () => import("@/pages/Dashboard/Faq/Guide/app.vue"),
        },{
          path: "shop",
          name: "CloudShop",
          menu_name: "商城服务",
          component: () => import("@/pages/Dashboard/Faq/Guide/Shop"),
        },{
          path: "customer",
          name: "Customer",
          menu_name: "客服服务",
          component: () => import("@/pages/Dashboard/Faq/Guide/Customer"),
        },{
          path: "ota",
          name: "Guide OTA",
          menu_name: "OTA服务",
          component: () => import("@/pages/Dashboard/Faq/Guide/Ota.vue"),
        },{
          path: "ai",
          name: "AI",
          menu_name: "AI服务",
          component: () => import("@/pages/Dashboard/Faq/Guide/AI.vue"),
        },{
          path: "cellular",
          name: "APPCellular",
          menu_name: "蜂窝服务",
          component: () => import("@/pages/Dashboard/Faq/Guide/Cellular.vue"),
        },{
          path: "applive",
          name: "APPLive",
          menu_name: "直播服务",
          component: () => import("@/pages/Dashboard/Faq/Guide/Live.vue"),
        },{
          path: "wakeup",
          name: "Wakeup",
          menu_name: "唤醒服务",
          component: () => import("@/pages/Dashboard/Faq/Guide/Wakeup.vue"),
        },{
          path: "thing",
          name: "thing",
          menu_name: "物的模型服务",
          component: () => import("@/pages/Dashboard/Faq/Guide/Thing.vue"),
        }]
    },{
      path     : "cloud",
      name     : "Cloud",
      menu_name: "云端开发指南",
      redirect : "/zh/cloud/cloudstruct",
      component: {render: e => e('router-view')},
      children : [{
          path: "cloudstruct",
          name: "Struct",
          menu_name: "请求结构",
          component: () => import("@/pages/Dashboard/Faq/Cloud/ReqStruct.vue"),
        },{
          path: "cloudauth",
          name: "Cloud_Auth",
          menu_name: "鉴权机制",
          component: () => import("@/pages/Dashboard/Faq/Cloud/Auth.vue"),
        },{
          path: "product",
          name: "FAQ Product",
          menu_name: "产品管理",
          component: () => import("@/pages/Dashboard/Faq/Cloud/Product.vue"),
        },{
          path: "device",
          name: "Cloud_DeviceInfo",
          menu_name: "设备消息",
          component: () => import("@/pages/Dashboard/Faq/Cloud/DeviceInfo.vue"),
        },{
          path: "user",
          name: "UserManage",
          menu_name: "用户管理",
          component: () => import("@/pages/Dashboard/Faq/Cloud/UserManage.vue"),
        },{
          path: "shop",
          name: "Faq Cloud Shop",
          menu_name: "商城服务",
          component: () => import("@/pages/Dashboard/Faq/Cloud/CloudShop.vue"),
        },{
          path: "cloudoss",
          name: "CloudOSS",
          menu_name: "云回放服务",
          component: () => import("@/pages/Dashboard/Faq/Cloud/OSS.vue"),
        },{
          path: "cloudlive",
          name: "CloudLive",
          menu_name: "直播服务",
          component: () => import("@/pages/Dashboard/Faq/Cloud/Live.vue"),
        }]
    },{
      path     : "dev",
      name     : "dev",
      menu_name: "设备端开发指南",
      redirect : "/zh/dev/devauth",
      component: {render: e => e('router-view')},
      children : [{
          path: "devauth",
          name: "Dev_Auth",
          menu_name: "鉴权机制",
          component: () => import("@/pages/Dashboard/Faq/Dev/Auth.vue"),
      },{
          path: "info",
          name: "DevInfo",
          menu_name: "设备信息",
          component: () => import("@/pages/Dashboard/Faq/Dev/Info.vue"),
      },{
          path: "oss",
          name: "DevOSS",
          menu_name: "云存储服务",
          component: () => import("@/pages/Dashboard/Faq/Dev/OSS.vue"),
      },{
          path: "devlive",
          name: "DevLive",
          menu_name: "直播服务",
          component: () => import("@/pages/Dashboard/Faq/Dev/Live.vue"),
      },{
          path: "devthing",
          name: "DevThing",
          menu_name: "物的模型服务",
          component: () => import("@/pages/Dashboard/Faq/Dev/Thing.vue"),
      },{
          path: "devtime",
          name: "DevTime",
          menu_name: "时间服务",
          component: () => import("@/pages/Dashboard/Faq/Dev/Time.vue"),
      }]
    },{
      path: "data",
      name: "guideData",
      menu_name: "通用数据定义",
      component: () => import("@/pages/Dashboard/Faq/Guide/GuideData.vue"),
    },{
      path: "error",
      name: "Error",
      menu_name: "全局错误码",
      component: () => import("@/pages/Dashboard/Faq/Error/Error.vue"),
    },{
      path     : "pay",
      name     : "Pay",
      menu_name: "收款平台配置说明",
      redirect : "/zh/pay/ali",
      component: {render: e => e('router-view')},
      children : [{
          path: "ali",
          name: "Ali",
          menu_name: "配置支付宝支付",
          component: () => import("@/pages/Dashboard/Faq/PayPlatform/Ali.vue"),
        },{
          path: "wx",
          name: "WX",
          menu_name: "配置微信支付",
          component: () => import("@/pages/Dashboard/Faq/PayPlatform/Wx.vue"),
        },{
          path: "paypal",
          name: "paypal",
          menu_name: "配置PayPal支付",
          component: () => import("@/pages/Dashboard/Faq/PayPlatform/Paypal.vue"),
      }]
    },{
      path     : "common",
      name     : "Common FAQ",
      menu_name: "FAQ 常见问题解答",
      redirect : "/zh/common/use",
      component: {render: e => e('router-view')},
      children : [{    
          path : "use",
          name : "Common_Use",
          menu_name : "使用教程",
          component: () => import("@/pages/Dashboard/Faq/Common/CommonUse.vue"),
        },{
          path : "ios",
          name: "Common_Ios",
          menu_name : "ISO大洲/国家地区编码",
          component: () => import("@/pages/Dashboard/Faq/Common/CommonIos.vue"),
      }]
    },
  ],
}
let enFaqMenu = {
  path: "/en",
  component: () => import("@/pages/Dashboard/FaqEN/Layout.vue"),
  name: "EN_FAQ",
  redirect: "/en/info",
  children: [
    {
      path     : "info",
      name     : "EN_ProductIntroduction",
      menu_name: "产品介绍",
      component: () => import("@/pages/Dashboard/FaqEN/Info/ProductIntroduction.vue"),
    },{
      path     : "guide",
      name     : "EN_Guide",
      menu_name: "客户端开发指南",
      redirect : "/en/guide/apweb",
      component: {render: e => e('router-view')},
      children : [{
          path: "start",
          name: "EnStart",
          menu_name: "初始化",
          component: () => import("@/pages/Dashboard/FaqEN/Guide/Start.vue"),
        },{
          path: "apweb",
          name: "EN_Apweb",
          menu_name: "AP配网流程",
          component: () => import("@/pages/Dashboard/FaqEN/Guide/Apweb.vue"),
        },{
          path: "qrcode",
          name: "EN_Qrcode",
          menu_name: "二维码配网流程",
          component: () => import("@/pages/Dashboard/FaqEN/Guide/Qrcode.vue"),
        },{
          path: "user",
          route_name: "EN_Guide User",
          menu_name: "用户服务",
          component: () => import("@/pages/Dashboard/FaqEN/Guide/User.vue"),
        },{
          path: "device",
          name: "EN_Guide_DeviceInfo",
          menu_name: "设备管理",
          component: () => import("@/pages/Dashboard/FaqEN/Guide/Device.vue"),
        },{
          path: "msgservice",
          name: "EN_Guide_MsgService",
          menu_name: "消息服务",
          component: () => import("@/pages/Dashboard/FaqEN/Guide/MsgService.vue"),
        },{
          path: "cloud",
          name: "EN_Guide_CloudService",
          menu_name: "云存储服务",
          component: () => import("@/pages/Dashboard/FaqEN/Guide/CloudService.vue"),
        },{
          path: "camera",
          name: "EN_Camera",
          menu_name: "摄像头服务",
          component: () => import("@/pages/Dashboard/FaqEN/Guide/Camera.vue"),
        },{
          path: "app",
          name: "EN_App",
          menu_name: "APP服务",
          component: () => import("@/pages/Dashboard/FaqEN/Guide/app.vue"),
        },{
          path: "shop",
          name: "EN_CloudShop",
          menu_name: "商城服务",
          component: () => import("@/pages/Dashboard/FaqEN/Guide/Shop"),
        },{
          path: "customer",
          name: "EN_Customer",
          menu_name: "客服服务",
          component: () => import("@/pages/Dashboard/FaqEN/Guide/Customer"),
        },{
          path: "ota",
          name: "EN_Guide OTA",
          menu_name: "OTA服务",
          component: () => import("@/pages/Dashboard/FaqEN/Guide/Ota.vue"),
        },{
          path: "ai",
          name: "EN_AI",
          menu_name: "AI服务",
          component: () => import("@/pages/Dashboard/FaqEN/Guide/AI.vue"),
        },{
          path: "cellular",
          name: "EnAPPCellular",
          menu_name: "蜂窝服务",
          component: () => import("@/pages/Dashboard/FaqEN/Guide/Cellular.vue"),
        },{
          path: "applive",
          name: "EnAPPLive",
          menu_name: "直播服务",
          component: () => import("@/pages/Dashboard/FaqEN/Guide/Live.vue"),
        },{
          path: "wakeup",
          name: "EN_Wakeup",
          menu_name: "唤醒服务",
          component: () => import("@/pages/Dashboard/FaqEN/Guide/Wakeup.vue"),
        }]
    },{
      path     : "cloud",
      name     : "EN_Cloud",
      menu_name: "云端开发指南",
      redirect : "/en/cloud/cloudstruct",
      component: {render: e => e('router-view')},
      children : [{
          path: "cloudstruct",
          name: "EN_Struct",
          menu_name: "请求结构",
          component: () => import("@/pages/Dashboard/FaqEN/Cloud/ReqStruct.vue"),
        },{
          path: "cloudauth",
          name: "EN_Auth",
          menu_name: "鉴权机制",
          component: () => import("@/pages/Dashboard/FaqEN/Cloud/Auth.vue"),
        },{
          path: "product",
          name: "EN_FAQ Product",
          menu_name: "产品管理",
          component: () => import("@/pages/Dashboard/FaqEN/Cloud/Product.vue"),
        },{
          path: "device",
          name: "EN_Cloud_DeviceInfo",
          menu_name: "设备消息",
          component: () => import("@/pages/Dashboard/FaqEN/Cloud/DeviceInfo.vue"),
        },{
          path: "user",
          name: "EN_UserManage",
          menu_name: "用户管理",
          component: () => import("@/pages/Dashboard/FaqEN/Cloud/UserManage.vue"),
        },{
          path: "shop",
          name: "EN_Faq Cloud Shop",
          menu_name: "商城服务",
          component: () => import("@/pages/Dashboard/FaqEN/Cloud/CloudShop.vue"),
        },{
          path: "cloudlive",
          name: "EnCloudLive",
          menu_name: "直播服务",
          component: () => import("@/pages/Dashboard/Faq/Cloud/Live.vue"),
        }]
    },{
      path     : "dev",
      name     : "Dev",
      menu_name: "设备端开发指南",
      redirect : "/en/dev/devauth",
      component: {render: e => e('router-view')},
      children : [{
          path: "devauth",
          name: "Auth",
          menu_name: "鉴权机制",
          component: () => import("@/pages/Dashboard/FaqEN/Dev/Auth.vue"),
      },{
          path: "info",
          name: "EnDevInfo",
          menu_name: "设备信息",
          component: () => import("@/pages/Dashboard/FaqEN/Dev/Info.vue"),
      },{
          path: "oss",
          name: "EnDevOSS",
          menu_name: "云存储服务",
          component: () => import("@/pages/Dashboard/FaqEN/Dev/OSS.vue"),
      },{
          path: "devlive",
          name: "EnDevLive",
          menu_name: "直播服务",
          component: () => import("@/pages/Dashboard/FaqEN/Dev/Live.vue"),
      },{
          path: "devthing",
          name: "EnDevThing",
          menu_name: "物的模型服务",
          component: () => import("@/pages/Dashboard/FaqEN/Dev/Thing.vue"),
      },{
          path: "devtime",
          name: "EnDevTime",
          menu_name: "时间服务",
          component: () => import("@/pages/Dashboard/FaqEN/Dev/Time.vue"),
      }]
    },{
      path: "data",
      name: "EN_guideData",
      menu_name: "通用数据定义",
      component: () => import("@/pages/Dashboard/FaqEN/Guide/GuideData.vue"),
    },{
      path: "error",
      name: "EN_Error",
      menu_name: "全局错误码",
      component: () => import("@/pages/Dashboard/FaqEN/Error/Error.vue"),
    },{
      path     : "pay",
      name     : "EN_Pay",
      menu_name: "收款平台配置说明",
      redirect : "/en/pay/ali",
      component: {render: e => e('router-view')},
      children : [{
          path: "ali",
          name: "EN_Ali",
          menu_name: "配置支付宝支付",
          component: () => import("@/pages/Dashboard/FaqEN/PayPlatform/Ali.vue"),
        },{
          path: "wx",
          name: "EN_WX",
          menu_name: "配置微信支付",
          component: () => import("@/pages/Dashboard/FaqEN/PayPlatform/Wx.vue"),
        },{
          path: "paypal",
          name: "EN_paypal",
          menu_name: "配置PayPal支付",
          component: () => import("@/pages/Dashboard/FaqEN/PayPlatform/Paypal.vue"),
      }]
    },{
      path     : "common",
      name     : "EN_Common FAQ",
      menu_name: "FAQ 常见问题解答",
      redirect : "/en/common/use",
      component: {render: e => e('router-view')},
      children : [{    
          path : "use",
          name: "EN_Common_Use",
          menu_name : "使用教程",
          component: () => import("@/pages/Dashboard/FaqEN/Common/CommonUse.vue"),
        },{
          path : "ios",
          name: "EN_Common_Ios",
          menu_name : "ISO大洲/国家地区编码",
          component: () => import("@/pages/Dashboard/FaqEN/Common/CommonIos.vue"),
      }]
    },
  ],
}
let authPages = {
  path: "/",
  name: "Authentication",
  redirect: "/zh/info",
};


const routes = [
  // componentMenu,
  // formsMenu,
  // tablesMenu,
  // mapsMenu,
  // pagesMenu,
  authPages,

  faqMenu,
  enFaqMenu,
];

export default routes;
